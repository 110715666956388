import React from "react";
import theme from "theme";
import { Theme, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"szemelyisegteszt"} />
		<Helmet>
			<title>
				Sikeres Kommunikáció - Sziklay Bence
			</title>
			<meta name={"description"} content={"Ismerd fel a másokban mélyen rejlő érzelmeket, elrejtett félelmeket, bizonytalanságokat és igényeket, mindezt akár hat percen belül."} />
			<meta property={"og:title"} content={"Sikeres Kommunikáció - Sziklay Bence"} />
			<meta property={"og:description"} content={"Ismerd fel a másokban mélyen rejlő érzelmeket, elrejtett félelmeket, bizonytalanságokat és igényeket, mindezt akár hat percen belül."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/mesterkulcs.webp?v=2024-03-07T19:53:21.189Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/655c9b91867c86001f18116f/images/small_logo_favic.png?v=2023-11-27T21:50:13.746Z"} />
		</Helmet>
		<Components.GlobalHeaderNavBar />
		<Image src="https://uploads.quarkly.io/655c9b91867c86001f18116f/images/hamarosan.png?v=2023-12-14T13:45:38.514Z" display="block" srcSet="https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/hamarosan.png?v=2023-12-14T13%3A45%3A38.514Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/hamarosan.png?v=2023-12-14T13%3A45%3A38.514Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/hamarosan.png?v=2023-12-14T13%3A45%3A38.514Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/hamarosan.png?v=2023-12-14T13%3A45%3A38.514Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/hamarosan.png?v=2023-12-14T13%3A45%3A38.514Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/hamarosan.png?v=2023-12-14T13%3A45%3A38.514Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/655c9b91867c86001f18116f/images/hamarosan.png?v=2023-12-14T13%3A45%3A38.514Z&quality=85&w=3200 3200w" sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw" />
		<Components.FooterGlobal />
	</Theme>;
});